@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: Lato;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  thead {
    @apply bg-secondary-500/10;
  }

  .btn-primary {
    @apply text-center p-1 py-2 rounded-md bg-secondary-500 text-white text-sm cursor-pointer;
  }

  .btn-secondary {
    @apply w-full text-center p-1 py-2 rounded-md border-[0.1rem] border-secondary-500 text-secondary-900 text-sm cursor-pointer;
  }

  .btn-default {
    @apply w-full text-center p-1 py-2 rounded-md bg-gray-200 text-sm cursor-pointer;
  }

  .input-floating-label-primary {
    @apply transform transition-all absolute top-2 left-2 h-3/5 flex items-center text-center px-2 text-xs group-focus-within:bg-white peer-valid:bg-white group-focus-within:h-2/5 peer-valid:h-2/5 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:px-1 peer-valid:px-1 group-focus-within:text-secondary-500;
  }

  /* .text-header-1 {
    @apply text-xl font-extrabold;
  } */
  .text-header-2 {
    @apply text-2xl font-extrabold;
  }
  .text-header-3 {
    @apply text-xl font-extrabold;
  }
}
